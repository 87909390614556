.cheese_img {
  width: 100%;
  height: auto;
  opacity: 0;
}

.show-cheese {
  opacity: 1;
}

.matrixBuilder-rows-items:hover > .cheese_img {
  opacity: 0.5;
}
